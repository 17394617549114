import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat, faHeart, faBook } from '@fortawesome/free-solid-svg-icons';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div>
            <Navbar bg="primary" variant="dark" expand="md" fixed="top">
                <Container>
                    <Navbar.Brand as={NavLink} to="/">Kayleigh Valentines</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as="div">
                                <NavLink to="/poem" className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'}>
                                    <FontAwesomeIcon icon={faBook} /> Poem
                                </NavLink>
                            </Nav.Link>
                            <Nav.Link as="div">
                                <NavLink to="/love" className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'}>
                                    <FontAwesomeIcon icon={faHeart} /> Love
                                </NavLink>
                            </Nav.Link>
                            <Nav.Link as="div">
                                <NavLink to="/cher" className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'}>
                                    <FontAwesomeIcon icon={faCat} /> Cher
                                </NavLink>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container style={{ marginTop: '100px' }}>
                {children}
            </Container>
        </div>
    );
};

export default Layout;