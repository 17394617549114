import React from 'react';
import { Container, Card } from 'react-bootstrap';

const Cher: React.FC = () => {
    return (
        <Container>
            <Card className="text-white bg-secondary mb-3">
                <Card.Header>Cher</Card.Header>
                <Card.Body>
                    <Card.Title className="card-title">Cher The Adventurous Cat</Card.Title>
                    <Card.Text className="card-text">
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>Who's the most beautiful cat in all the land?</p>
                        <p>Her fur looks like mud and sand.</p>
                        <p>Soft little paws, and eyes so bright,</p>
                        <p>She dances in fields from day to night.</p>
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>Cher, Cher, the wandering cat,</p>
                        <p>Roaming the meadows, where the other cats chat.</p>
                        <p>Soon she’ll find a home so grand,</p>
                        <p>Joining her family in their traveling van.</p>
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>Through the golden grass she leaps and plays,</p>
                        <p>Chasing the sunlight, soaking in rays.</p>
                        <p>A little explorer, fierce and free,</p>
                        <p>Her heart’s so wild as you shall see.</p>
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>Cher, Cher, the wandering cat,</p>
                        <p>Roaming the meadows, where the other cats chat.</p>
                        <p>Soon she’ll find a home so grand,</p>
                        <p>Joining her family in their traveling van.</p>
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>At night she purrs by the campfire’s glow,</p>
                        <p>Dreams of adventures, fields where she’ll go.</p>
                        <p>With a waggly tail, she’ll shout and wale,</p>
                        <p>Cher’s a tale we’ll always regale.</p>
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>Now she sits by the van, eyes up at the moon,</p>
                        <p>Humming with joy, knowing soon.</p>
                        <p>The road may wind, the world may spin,</p>
                        <p>But with her family, she’s ready to begin.</p>
                        <hr style={{width: '40%', margin: '0 auto'}}/>
                        <br/>
                        <p>Cher, Cher, the wandering cat,</p>
                        <p>Roaming the meadows, where the wildflowers chat.</p>
                        <p>Now she’s found her home so grand,</p>
                        <p>With her family in their traveling van.</p>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Cher;