import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Cher from './pages/Cher';
import Love from './pages/Love';
import Poem from './pages/Poem';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/quartz/bootstrap.min.css';
import Layout from './Layout';
import './styles.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="/cher" element={<Cher />} />
                    <Route path="/love" element={<Love />} />
                    <Route path="/poem" element={<Poem />} />
                </Routes>
            </Layout>
        </Router>
    </React.StrictMode>
);

reportWebVitals();