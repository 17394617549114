import React, {useEffect, useState} from 'react';
import {Container, Card, Spinner} from 'react-bootstrap';
import {FaFeatherAlt} from 'react-icons/fa';
import {Poem} from '../models/Poem';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/quartz/bootstrap.min.css';

const PoemPage: React.FC = () => {
    const [poem, setPoem] = useState<Poem | null>(null);

    useEffect(() => {
        const fetchPoems = async () => {
            try {
                const endpointUrl = 'https://kayleigh-api.yagasoft.co.uk';
                const response = await fetch(`${endpointUrl}/poems`);
                const dataJson = await response.json();
                const today = new Date().toISOString().split('T')[0];
                const todayPoem = dataJson.find((poem: Poem) => {
                    const poemDate = new Date(poem.date).toISOString().split('T')[0];
                    return poemDate === today;
                });
                setPoem(todayPoem || null);
            } catch (error) {
                console.error('Error fetching poems:', error);
            }
        };

        fetchPoems();
    }, []);

    if (!poem) {
        return (
            <Container style={{textAlign: 'center', marginTop: '20px'}}>
                <Card className="text-white bg-secondary mb-3">
                    <Card.Header>Poem</Card.Header>
                        <Card.Body>
                            <Spinner animation="border"/>
                            <h6 style={{marginTop: '20px'}}>Loading today's poem...</h6>
                        </Card.Body>
                </Card>
            </Container>
        );
    }

    return (
        <Container>
            <Card className="text-white bg-secondary mb-3">
                <Card.Header>Poem</Card.Header>
                <Card.Body>
                    <Card.Title className="card-title">
                        <FaFeatherAlt size={48}/> Poem Day {poem.day}
                    </Card.Title>
                    <Card.Text className="card-text">
                        <figure className="text-center">
                            <blockquote className="blockquote" style={{maxWidth: '600px', margin: '0 auto'}}>
                                <p className="mb-0">{poem.poemContent}</p>
                            </blockquote>
                        </figure>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default PoemPage;