import React, { useState } from 'react';
import { Container, Card, Button, Spinner, Toast } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';

const Love: React.FC = () => {
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSendLove = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://kayleigh-api.yagasoft.co.uk/annoying/annoy', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: 'Sending love!' }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.text();
            console.log('Success:', data);
            setSuccessMessage('Love sent successfully!');
        } catch (error) {
            console.error('Error sending love:', error);
            setSuccessMessage('Failed to send love.');
        } finally {
            setLoading(false);
            setShowToast(true);
        }
    };

    return (
        <Container>
            <Card className="text-white bg-secondary mb-3">
                <Card.Header>Love</Card.Header>
                <Card.Body>
                    <Card.Title className="card-title">
                        <FaHeart size={100} />
                    </Card.Title>
                    <Card.Text className="card-text">
                        <Button
                            variant="primary"
                            onClick={handleSendLove}
                            disabled={loading}
                        >
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Click To Send Love'}
                        </Button>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 1, // Ensure the toast is above other elements
                }}
            >
                <Toast.Header closeButton={false}>
                    <strong className="me-auto">{successMessage?.includes('successfully') ? 'Success' : 'Error'}</strong>
                    <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
                </Toast.Header>
                <Toast.Body>{successMessage}</Toast.Body>
            </Toast>
        </Container>
    );
};

export default Love;