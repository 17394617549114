import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col, Spinner } from 'react-bootstrap';
import { Image } from './models/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/quartz/bootstrap.min.css';

const App: React.FC = () => {
    const [images, setImages] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const endpointUrl = 'https://kayleigh-api.yagasoft.co.uk';
                const response = await fetch(`${endpointUrl}/images`);
                const dataJson = await response.json();
                const base64Images = dataJson.map((data: Image) => {
                    return `data:image/jpeg;base64,${data.data}`;
                });
                setImages(base64Images);
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <Container fluid>
            <Card className="text-white bg-secondary mb-3" style={{ width: '100%' }}>
                <Card.Header>Happy Valentines Day Kayleigh!</Card.Header>
                <Card.Body>
                    <Card.Title>A Collection Of Our Photos</Card.Title>
                    <Card.Text>
                        {loading ? (
                            <div className="text-center">
                                <Spinner animation="border" />
                                <h6 style={{ marginTop: '20px' }}>Loading images...</h6>
                            </div>
                        ) : (
                            <Row>
                                {images.map((src, index) => (
                                    <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                                        <Card>
                                            <Card.Img variant="top" src={src} alt={`Placeholder ${index + 1}`} style={{height: '400px', borderRadius: '5px'}}/>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default App;